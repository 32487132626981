@font-face {
  font-family: 'SukhumvitSet';
  src: url(fonts/SukhumvitSet-Medium.ttf);
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 'bold'
}
.App {
  font-family: 'SukhumvitSet';
  text-align: center;
}
.bg-black {
  background: #010101;
}
.bg-dark-cyan {
  background: #1F4445;
}
.bg-sq-green {
  background: #058F8F;
}
.h-70 {
  height: 70%;
}
.h-30 {
  height: 30%;
}
.banner-message {
  font-size: 7rem;
  font-weight: 600;
  line-height: 9rem;
  padding-top: 1rem;
}
.banner-temp {
  font-size: 7rem;
  font-weight: 400;
  padding: 0px 2rem;
}
.banner-temp-energy {
  font-size: 7rem;
  font-weight: 400;
  padding-top: 1rem;
  margin-bottom: -5rem;
}
.banner-icon {
  height: 100%;
  max-height: 200px;
}
.banner-video-bg {
  position: relative;
  object-fit: cover;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 840px;
  /* min-height: 640px;
  max-height: 70vh; */
}
.box-video-bg {
  height: 240px;
  position: relative;
  object-fit: cover;
  padding: 0 !important;
  overflow: hidden;
}
.box-video-bg-200 {
  height: 200px;
  position: relative;
  object-fit: cover;
  padding: 0 !important;
  overflow: hidden;
}
.banner-energy-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.bottombar-energy-progress {
  padding: 0;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  color: #ffffff;
}
.bottombar-energy-temp {
  font-size: 2rem;
  font-weight: 400;
  padding-top: 1rem;
}
.bottombar-energy-icon {
  height: 100%;
  max-height: 80px;
}
.bottombar-energy-message {
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
  text-align: start;
  letter-spacing: 1.125px;
}
.bottombar-energy-content {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 3rem;
}
.gradient-custom {
  /* fallback for old browsers */
  background: #fcff2e;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to bottom, rgba(255,220,39,1), rgba(1,255,0,1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(360deg, #01FF00 41.15%, #FFDC27 101.3%);
}
.gradient-mix-blend {
  background: rgb(67,148,42);
  background: linear-gradient(360deg, rgba(67,148,42,1) 41.15%, rgba(144,131,48,1) 101.3%);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.vertical-divider {
  border: 4px solid #FFFFFF;
  transform: rotate(90deg);
}